import AuthService from "../services/AuthService";
import router from "../router";
import {userCompanyData} from "./api/usercompany"
const authservice = new AuthService();

const actions = {
  async completeLogin({ commit }, user) {
    if (user !== undefined || user !== null) {
      commit("updateUser", user);
      commit("updateIsAuthenticated", true);

      var company = await userCompanyData.searchLoggedInCompany();
      if (company !== "" && company != null) {
        router.push("/home");
      } else {
        router.push("/logout");
      }     

    } else {
      console.log("DEBUG: couldnt login the user.");
      router.push('/login').catch(err => { console.log(err); });
    }
    console.log("DEBUG: Store login complete.");
  },

  logout({ commit }) {
    commit("updateUser", null);
    commit("updateIsAuthenticated", false);
    authservice.logout();
  },

  editProfile({ commit }) {
    authservice.editProfile();
  },

  setIsProgressing({commit}, value){
    commit("IsProgressing", value);
  },

  setGlobalMessage({commit}, value){
    commit("globalMessage", value);
  },

  setApplicationVersion({commit}, value){
    commit("updateApplicationVersion", value);
  },
};

export default actions;
