<script>
import appConfig from '@src/app.config'

import {
    registerLicense
} from '@syncfusion/ej2-base';

export default {
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `${title} | ${appConfig.title}` : appConfig.title
        },
    },
    mounted() {
        registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9TdUVjW31bcnxWTmlY');
    }
}
</script>
<template>
<div id="app">
    <vue-page-transition name="fade">
        <!-- <RouterView :key="$route.fullPath" /> -->
        <RouterView />
    </vue-page-transition>
</div>
</template>

<style lang="scss">
@import '@design';
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>
