// axios
import axios from 'axios'
import store from './state/store'
import AuthService from './services/AuthService'
import { CoreAPIConfig } from './state/api/config'

const coreUrl = `${CoreAPIConfig.COREAPI}`
const coreAPIInstance = new axios.create({ baseURL: coreUrl })

const authservice = new AuthService()

coreAPIInstance.interceptors.request.use(
  async (config) => {
    store.dispatch('setIsProgressing', true)
    let token = await authservice.getSilentToken('core')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers['x-app'] = 'management'
    } else {
      return store.dispatch('logout')
    }
    return config
  },
  function(err) {
    store.dispatch('setIsProgressing', false)
    if (err.response.status === 401) {
      return store.dispatch('logout')
    } else {
      console.log(err.response.status)
    }
    return Promise.reject(err)
  }
)

coreAPIInstance.interceptors.response.use(
  function(response) {
    store.dispatch('setIsProgressing', false)
    return response
  },
  function(err) {
    store.dispatch('setIsProgressing', false)
    if (err.response.status === 401) {
      return store.dispatch('logout')
    } else {
      console.log(err.response.status)
    }
    return Promise.reject(err)
  }
)

export const axiosInstance = {
  coreAPIInstance,
}
