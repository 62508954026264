import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@state/store'
import { mapActions } from "vuex";
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'page',
})

const router = new VueRouter({
  mode: 'history',
  methods: {
    ...mapActions(["login"])
  },
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: "common",
      component: () => import("./layouts/nomenu.vue"),
      children: [
        {
          path: '/logout',
          name: 'logout',
          component: () => (import('@views/common/logout'))
        },
        {
          path: '/login',
          name: 'login',
          component: () => (import('@views/common/landing'))
        },
        {
          path: '/completeLogin',
          name: 'completeLogin',
          component: () => (import('@views/common/completelogin'))
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@views/common/error-404'),
          meta: { authRequired: false }
        },
        {
          path: '/500',
          name: '500',
          component: () => import('@views/common/error-500'),
          meta: { authRequired: false }
        },
        {
          path: '*',
          redirect: '404',
        },
      ]
    },
    {
      path: "/",
      redirect: "/login",
      component: () => import("./layouts/main.vue"),
      children: [
        {
          path: "/home",
          name: "home",
          component: () => import("./views/Home/dashboard.vue"),
          meta: {
            requireAuth: true
          }
        },
        {
          path: '/Event/PendingEvents',
          name: 'Create Event',
          component: () => import('@views/Event/PendingApproval'),
          meta: { authRequired: true },    
        },
        // {
        //   path: '/Event/viewEvent',
        //   name: 'viewEvent',
        //   component: () => (import('@views/Event/ViewEvent')),
        //   meta: { authRequired: true },    
        // },
        {
          path: '/Event/AllActiveEvents',
          name: 'ActiveEvents',
          component: () => (import('@views/Event/AllActiveEvents')),
          meta: { authRequired: true },    
        },
        {
          path: '/Event/CreateEvent',
          name: 'create-events',
          component: () => (import('@views/Event/CreateEvent')),
          meta: { authRequired: true },    
        },
        {
          path: '/Enterprise/Procedures',
          name: 'Emergency Procedures',
          component: () => (import('@views/Procedures/page')),
          meta: { authRequired: true },    
        },
        {
          path: '/Customers',
          name: 'Customers',
          component: () => (import('@views/Customer/Customer')),
          meta: { authRequired: true },    
        },
        {
          path: '/EditCustomer',
          name: 'editcustomer',
          component: () => (import('@views/Customer/editCustomer')),
          meta: { authRequired: true },    
        }, 
        {
          path: '/AddCustomer',
          name: 'addCustomer',
          component: () => (import('@views/Customer/addCustomer')),
          meta: { authRequired: true },    
        }, 
        {
          path: '/Locations/:id?',
          name: 'locations',
          component: () => (import('@views/Location/LocationPage')),
          meta: { authRequired: true },    
        },
        {
          path: '/EditLocation',
          name: 'EditLocation',
          component: () => (import('@views/Location/LocationProfile')),
          meta: { authRequired: true },    
        },  
      ]
    }
  ],   
})

router.afterEach((routeTo, routeFrom) => {
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  if (routeFrom.name !== null) {
  }

  if (routeTo.fullPath === routeFrom.fullPath && routeTo.fullPath !== "/") {
    return next(false);
  }

  const authRequired = routeTo.matched.some((route) => route.meta.requireAuth)
  if (!authRequired) return next()

  if (!store.state.isAuthenticated) {
    return router.push("/login")
  }

  return next();
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              if (routeFrom.name === args[0].name) {
                NProgress.done()
              }
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})


export default router
