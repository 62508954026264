import { axiosInstance } from "../../axios";

const GETLoggedInCompany = "api/Company/GetSuperAdminCompany";
const GETSwitchLoggedInCompany = "api/Company/SwitchLoggedInCompany/";
const GETSearchCompanyByUser = "api/Company/SearchByUser";

const searchLoggedInCompany = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(GETLoggedInCompany);
    return response.data;
  } catch (error) {
    return null;
  }
};

const switchLoggedInCompany = async function(companyId) {
  try {
    const response = await axiosInstance.coreAPIInstance.get(GETSwitchLoggedInCompany + companyId);
    return response.data;
  } catch (error) {
    return null;
  }
};

const searchByUser = async function() {
  try {
    const response = await axiosInstance.coreAPIInstance.get(GETSearchCompanyByUser);
    return parseList(response);
  } catch (error) {
    return null;
  }
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const userCompanyData = {
  searchLoggedInCompany,
  searchByUser,
  switchLoggedInCompany
};
