import * as Msal from "@azure/msal-browser";
import { BaseConfig, CoreAPIConfig } from "../state/api/config";

export default class AuthService {
  constructor() {
    this.app = new Msal.PublicClientApplication({
      auth: {
        clientId: `${BaseConfig.AppClientID}`,
        authority: `${BaseConfig.AppLoginAuthority}`,
        knownAuthorities: [`${BaseConfig.AppLoginAuthority}`],
        validateAuthority: false,
        navigateToLoginRequestUrl: true,
        redirectUri: `${BaseConfig.AppUrl}login`,
        postLogoutRedirectUri: `${BaseConfig.AppUrl}login`,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
        secureCookies: true
      }
    });
  }

  async generateTokenRequest(scope, silent) {
    let currentAccount = await this.getUser();
    if (currentAccount === undefined || currentAccount === null){
      return;
    }

    let tokenRequest = {};
    if (scope === 'core') {
      tokenRequest = {
        scopes: [`${CoreAPIConfig.CORESCOPE}`],
        account: currentAccount,
        forceRefresh: false,
        loginHint: currentAccount.username
      };      
    } 
    
    if (silent) {
      tokenRequest.forceRefresh = true;
      tokenRequest.loginHint = currentAccount.username;
    }

    return tokenRequest;
  } 

  async getSilentToken(scope) {
    let accessTokenRequest = await this.generateTokenRequest(scope, false);
    let accessToken = "";
    try {
      const tokenResponse = await this.app.acquireTokenSilent(accessTokenRequest);
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      accessTokenRequest =  await this.generateTokenRequest(scope, true);

      try {
        await this.app.acquireTokenPopup(accessTokenRequest);
      } catch (errorPopup) {
        return undefined;
      }
    }
    return accessToken;
  }

  async editProfile () {
    alert("editing profile, work in progress");
  }

  logout() {
    this.app.logoutRedirect({});
  }

  async getUser() {
    let allAccounts = await this.app.getAllAccounts();
    return allAccounts[0];
  }
}
